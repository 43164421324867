<template>
  <div>
    <div class="cardTitle">房屋管理</div>
    <div class="search-box">
      <a-row>
        <a-col :span="20">
          <a-space size="large">
            <a-input v-model="searchForm.name" placeholder="请输入房间号" />
            <a-select style="width: 200px" v-model='searchForm.manageEstateTypeId' placeholder="请选择房屋类型" >
				<a-select-option v-for="item in options.houseType" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
			</a-select>
            <a-select style="width: 200px" v-model='searchForm.isEnableLease' placeholder="请选择是否开启租赁" >
				<a-select-option v-for="item in options.isEnableLease" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
			</a-select>
            <a-button type="primary" @click="getData">查 询</a-button>
            <a-button @click="reset">重 置</a-button>
          </a-space>
        </a-col>
      </a-row>
    </div>
	<div class="search-box">
		<div style="display: flex">
			<a-button class="add-btn" @click="drawerConfig.addShow = true">添加房屋</a-button>
			<a-upload name="file" :action="`${$houseUpload}`"
				accept=".xls,.XLS,.xlsx,.XLSX" :showUploadList="false"
				:headers="uploadHeaders" @change="changeFile">
				<a-button><a-icon type="upload" /> 批量导入</a-button>
			</a-upload>
			<a-button @click="modelDownload">
				  <a-icon type="copy" />模版下载
			</a-button>
			<a-button  @click="drawerConfig.houseType = true">房屋配置</a-button>
		</div>
	</div>
    <div class="main">
      <div style="margin-bottom: 16px">
        <!-- 批量操作 -->
        <a-select
          type="primary"
          v-model="activeAction"
          :disabled="!hasSelected"
          :loading="loading"
          style="width: 120px"
          @change="Actions"
          placeholder="请选择操作"
        >
          批量
          <a-select-option v-for="item in ActionsList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `已选择 ${selectedRowKeys.length} 条` }}
          </template>
        </span>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :scroll="{ x: 1400 }"
		@change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :row-key="
          (record, index) => {
            return record.id;
          }
        "
      >
        <span slot="name" slot-scope="text, row">
          {{
            row.manageBuildingName +
            "栋/" +
            row.manageUnitName +
            "单元/" +
            row.floorLocation +
            "层-" +
            row.name +
            "室"
          }}
        </span>
        <span slot="action" slot-scope="text, row">
          <a-space>
            <a class="ant-dropdown-link" @click="edit(row)">编辑</a>
            <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
          </a-space>
        </span>
        <span slot="tags" slot-scope="tag">
          <a-tag
            :color="tag === 1 ? 'volcano' : tag === 2 ? 'geekblue' : 'green'"
          >
            {{ tag === 1 ? "闲置" : tag === 2 ? "在住" : "在租" }}
          </a-tag>
        </span>
        <span slot="switch" slot-scope="switchV, row">
          <a-switch
		  	checked-children="开" un-checked-children="关"
            :checked="switchV === 1"
            @change="handleEnable(row)"
          ></a-switch>
        </span>
      </a-table>
    </div>
    <a-drawer
      title="添加房屋"
      :width="720"
      :visible="drawerConfig.addShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <vue-form ref="form" @success='addSuccess' :typeList="typeList" :editId="editId"></vue-form>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="addHouse"> 提交 </a-button>
      </div>
    </a-drawer>
    <!-- 房屋类型 -->
    <a-drawer
      title="房屋设置"
      :width="720"
      :visible="drawerConfig.houseType"
      :body-style="{ paddingBottom: '80px' }"
      @close="drawerConfig.houseType=false"
    >
      <div class="drawer-content">
        房屋类型
        <a-divider></a-divider>
        <!-- tags -->
        <span v-for="item,index in typeList" :key="item.id">
          <a-input
            v-if="item.show==true"
            ref="input"
			v-focus
            type="text"
            size="small"
            :style="{ width: '78px','padding-bottom':'3px','margin-right':'7px' }"
            v-model="item.name"
            @blur="editType(item)"
            @keyup.enter="editType(item)"
          />
          <a-tag
            v-else-if="item.show==false" closable @close.prevent="delType(item.id)"
            @click="editInput(item,index)" >
              {{item.id+': '}}{{item.name}}
          </a-tag>
        </span>
        <!-- addTag -->
        <span>
          <a-input
            v-if="inputVisible"
            ref="addInput"
            type="text"
            size="small"
            :style="{ width: '78px','padding-bottom':'3px'}"
            :value="typeForm.name"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"
          />
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="addShow">
            <a-icon type="plus" /> 新增类型
          </a-tag>
        </span>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="drawerConfig.houseType=false">
          关闭
        </a-button>
      </div>
    </a-drawer>
	<a-modal title="批量导入操作成功" :visible="uploadVisible" @cancel="uploadVisible = false" :footer="null">
			<div>以下为导入失败的用户</div>
			<p v-for="(item,index) in uploadResult" :key="index">
				{{item}}
			</p>
	</a-modal>
  </div>
</template>

<script>
import store from "@/store";
import { columns, pagination, searchForm, ActionsList } from "./depend/config";
import { houseList, houseDel, isEnableLease, estateType, estateTypeInsert, estateTypeDel, estateTypeUpdate } from "@/api/basic/estate";
import vueForm from "./depend/form.vue";
export default {
	components: {
		vueForm,
	},
	directives: {
		focus: {
			// 指令的定义
			inserted: function (el) {
				el.focus()
			}
		}
	},
	data() {
		return {
			drawerConfig: {
				addShow: false,
				houseType: false
			},
			pagination,
			searchForm,
			columns,
			ActionsList,
			inputVisible: false,
			typeForm:{
				id: null,
				name: ''
			},
			editId:null,
			activeAction: undefined,
			loading: false,
			// 选择的index
			selectedRowKeys: [],
			tableChoosed: [],
			tableData: [],
			typeList:[],
			options:{
				houseType:[],//房屋类型
				isEnableLease:[//开启租赁
					{
						id:1,
						name:'开启'
					},
					{
						id:2,
						name:'关闭'
					},
				],
			},
			// 上传
			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
			//上传对话框
			uploadVisible: false,
			uploadResult: [],
			fileList: []
		};
	},
	created() {
		this.getData();
		this.getType()
	},
	methods: {
		// 获取房屋
		async getData() {
			const res = await houseList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total
		},
		// 获取类型
		async getType() {
			this.typeList = []
			const res = await estateType();
			this.options.houseType = res.data
			const arr = res.data;
			arr.forEach(ele => {
				ele.show = false;
			})
			this.typeList = arr;
		},
		reset(){
			this.searchForm.name= "",
			this.searchForm.isEnableLease = undefined,
			this.searchForm.manageEstateTypeId = undefined
			this.getData()
		},
		// 调用表单组件的提交
		addHouse() {
			this.$refs.form.addSubmit()
		},
		// 新增房屋后的回调
		addSuccess(){
			this.getData()
			this.addClose()
		},
		addClose() {
			this.drawerConfig.addShow = false;
			this.getType();
			this.editId = null;
			this.$refs.form.close()
		},
		edit(data){
			this.getType();
			this.editId = data.id
			this.drawerConfig.addShow = true;
		},
		// 新增类型
		addShow(){
			this.inputVisible = true;
			this.$nextTick(function() {
				this.$refs.addInput.focus();
			});
		},
		// 删除
		delType(id){
			this.$confirm({
				title: "是否删除",
				icon:'delete',
				onOk:async()=>{
					let res = await estateTypeDel({manageEstateTypeIds:[id]})
					if(res.code=== 200){
						this.$message.success(res.msg);
						this.getType()
					}else{
						this.$message.error(res.msg);
					}
				},
			})
		},
		// 打开编辑框 聚焦input
		editInput(obj,i) {
			this.$set(this.typeList[i],'show',true)
			this.$forceUpdate()
		},
		// 离开编辑框的回调
		async editType(item){
			let res = await estateTypeUpdate({
				id: item.id,
				name: item.name
			})
			if(res.code === 200){
				item.show  = false
				this.$forceUpdate()
				this.$message.success(res.msg)
			}else{
				this.$message.error(res.msg)
			}
		},
		handleInputChange(e) {
			this.typeForm.name = e.target.value;
		},
		async handleInputConfirm() {
			if(this.typeForm.name === ''){
				this.inputVisible = false;
				return
			}
			let res = await estateTypeInsert({
				name:this.typeForm.name,
			})
			if(res.code === 200){
				this.$message.success(res.msg);
				this.inputVisible = false;
				this.typeForm.name = ''
				this.getType()
			}else{
				this.$message.error(res.msg);
			}
		},
		// 租赁
		handleEnable: async function (data) {
			const res = await isEnableLease({
				estateId: data.id,
			});
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData()
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				icon: "delete",
				onOk: () => {
					houseDel({ estateIds: ids }).then((res) => {
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.getData();
						} else {
							this.$message.error(res.msg);
						}
					});
				},
			});
		},
		Actions(data) {
			// 批量删除
			if (data === 1) {
				this.del(this.selectedRowKeys);
				this.activeAction = undefined;
			}
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		//上传
		changeFile(data) {
			if(data.file.status == "done") {
				if(data.file.response.success == false) {
					this.$message.error(data.file.response.msg);
				} else {
					this.$message.success('操作成功');
					if(data.file.response.data) {
						this.uploadResult = data.file.response.data;
						this.uploadVisible = true;
					}
					this.getData();
				}
			}
		},
		//模版下载
		modelDownload() {
			window.open('https://saas.kaidalai.cn/excelModel/房屋导入模版.xlsx');
		}
 	},
	computed: {
		// 是否选择selection
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.role-card {
  line-height: 30px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
/deep/.ant-tag{
  margin-bottom: 8px;
}
</style>