<template>
  <div>
    <a-form-model
      layout="vertical"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="楼栋" prop="manageBuildingId">
            <a-select
              v-model="form.manageBuildingId"
              placeholder="请选择楼栋"
              @change='changeBuild'
            >
              <a-select-option v-for="item in options.building" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="单元" prop="manageUnitId">
            <a-select v-model="form.manageUnitId" placeholder="请选择单元" @change='changeUnit'>
              <a-select-option v-for="item in options.unit" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="楼层" prop="floorLocation">
            <a-input-number v-model.number="form.floorLocation"  placeholder="楼层" :min='0' :max='options.floor'/>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="房屋名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入房屋名称" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="室内面积" prop="indoorArea">
            <a-input
              type="number"
              v-model="form.indoorArea"
              placeholder="请输入室内面积"
              suffix="㎡"
              :min='0'
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="建筑面积" prop="constructionArea">
            <a-input
              v-model="form.constructionArea"
              type="number"
              suffix="㎡"
              placeholder="请输入建筑面积"
              :min='0'
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="房屋类型" prop="manageEstateTypeId">
            <a-select
              v-model="form.manageEstateTypeId"
              placeholder="请选择房屋类型"
            >
              <a-select-option v-for="item in options.houseType" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="是否开启租赁" prop="isEnableLease">
            <a-select placeholder='请选择是否开启租赁' v-model="form.isEnableLease" >
              <a-select-option v-for="item in options.isEnableLease" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div>房屋户型</div>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="厅" prop="houseTypeHall">
            <a-input type="number" :min="0" v-model="form.houseTypeHall" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="室" prop="houseTypeRoom">
            <a-input type="number" :min="0" v-model="form.houseTypeRoom" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="卫" prop="houseTypeBathroom">
            <a-input type="number" :min="0" v-model="form.houseTypeBathroom" />
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
		  <div>房屋照片</div>
          <a-upload
              :action="`${$upload}`"
              :headers="uploadHeaders"
              list-type="picture-card"
              accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
              :file-list="fileList"
              @preview="handlePreview"
              @change="fileChange"
			  :beforeUpload="beforeUpload"
            >
              <div v-if="fileList.length < 2">
                <!-- <a-icon type="plus" /> -->
                <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
		        </a-modal>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="备注" prop="remarks">
            <a-textarea v-model="form.remarks" :rows="4" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { form, rules, uploadHeaders } from "./form.js";
import { allBuilding, findByBuildingId, estateType, houseInsert, houseUpdate, houseInfo } from "@/api/basic/estate"
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}
export default {
	props: {
		editId: {
			default:null,
			type:Number
		},
		typeList: {
			default: null,
			type:Array
		}
	},
	data() {
		return {
			form,
			rules,
			uploadHeaders,
			fileList: [],
			previewVisible: false,
			imgLoading: false,
			previewImage: "",
			formType:'add',
			options:{
				building:[],//楼栋
				unit:[],//单元
				floor:0,//楼层
				houseType:[],//房屋类型
				isEnableLease:[//开启租赁
					{
						id:1,
						name:'开启'
					},
					{
						id:2,
						name:'关闭'
					},
				],
			}
		};
	},
	mounted() {
		allBuilding().then(res=>{
			this.options.building = res.data
		})
		estateType().then(res=>{
			this.options.houseType = res.data
		})
		if(this.editId !== null){
			this.getInfo(this.editId)
		}
	},
	methods: {
		async getInfo(id){
			this.formType = 'edit'
			let res = await houseInfo({estateId:id})
			this.form.id = id
			this.form.manageBuildingId = res.data.manageBuildingId
			// 楼栋改变
			await this.changeBuild(res.data.manageBuildingId)
			this.form.manageUnitId = res.data.manageUnitId
			for(let item of this.options.unit){
				if(item.id === res.data.manageUnitId){
					this.options.floor = item.floor
					this.form.floorLocation = res.data.floorLocation
				}
			}
			this.form.name = res.data.name
			this.form.manageEstateTypeId = res.data.manageEstateTypeId
			this.form.indoorArea = res.data.indoorArea
			this.form.constructionArea = res.data.constructionArea
			this.form.houseTypeHall = res.data.houseTypeHall
			this.form.houseTypeRoom = res.data.houseTypeRoom
			this.form.houseTypeBathroom = res.data.houseTypeBathroom
			this.form.remarks = res.data.remarks
			this.form.isEnableLease = res.data.isEnableLease
			// this.form.estateImgUrls = res.data.estateImgUrls
			const file = []
			for(let item of res.data.estateImgList){
				let obj = {
					name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
					url: this.$ImgUrl(item.url),
					uid:item.url.split('_')[1],
					status:'done',
					thumbUrl: this.$ImgUrl(item.url),
				}
				file.push(obj)
			}
			this.fileList = file
		},
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		async changeBuild(data){
			this.form.manageUnitId = undefined
			this.options.floor  = 0
			let res = await findByBuildingId({buildingId:data})
			this.options.unit = res.data
		},
		changeUnit(data){
			this.options.floor  = 0
			for(let item of this.options.unit){
				if(item.id === data){
					this.options.floor = item.floor
				}
			}
		},
		addSubmit(){
			let file = []
			for(let item of this.fileList){
				// 新增的照片
				if(item.response){
					file.push(item.response.data)
				}else{
				// 已添加的照片
					file.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			this.form.estateImgUrls = file
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					if(this.formType==='add'){
						let res = await houseInsert(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.success()
						}else{
							this.$message.error(res.msg)
						}
					}else if(this.formType==='edit'){
						let res = await houseUpdate(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.success()
						}else{
							this.$message.error(res.msg)
						}
					}
				}
			});
		},
		success(){
			this.$emit('success')
		},
		close(){
			this.$refs.ruleForm.resetFields();
			this.fileList = []
			this.formType = 'add'
		},
		handleCancel() {
			this.previewVisible = false;
		},
		fileChange(info) {
			this.fileList = info.fileList
		},
		handleChange({ fileList }) {
			this.fileList = fileList;
		},
		beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
	},
	watch: {
		editId(val){
			if(val!==null){
				this.getInfo(val)
			}
		},
		typeList(val) {
			this.options.houseType = val;
		}
	}
};
</script>

<style lang="less" scoped>
</style>