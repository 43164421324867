/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "导入编号",
		dataIndex: "id",
		width: "8%",
	},
	{
		title: "房屋名称",
		dataIndex: "manageBuildingName",
		scopedSlots: { customRender: "name" },
		width: "15%",
	},
	{
		title: "室内面积",
		dataIndex: "indoorArea",
		width: "12%",
	},
	{
		title: "建筑面积",
		dataIndex: "constructionArea",
		width: "12%",
	},
	{
		title: "房屋类型",
		dataIndex: "manageEstateTypeName",
		width: "15%",
	},
	{
		title: "居住状态",
		key: "tags",
		dataIndex: "residenceStatus",
		scopedSlots: { customRender: "tags" },
	},
	{
		title: "是否开启租赁",
		dataIndex: "isEnableLease",
		scopedSlots: { customRender: "switch" },
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
// 搜索项
export const searchForm = {
	name: "",
	isEnableLease: undefined,
	manageEstateTypeId:undefined
}
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
// 操作
export const ActionsList = [
	{
		label: "批量删除",
		value: 1,
	},
	// {
	// 	label: "批量导出",
	// 	value: 2,
	// },
]