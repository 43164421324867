/**
 * 表单
 * 
 */

// 表单
export const form = {
	id:undefined,
	manageBuildingId:undefined,
	manageUnitId:undefined,
	floorLocation:undefined,
	name:undefined,
	indoorArea:undefined,
	constructionArea:undefined,
	manageEstateTypeId:undefined,
	isEnableLease:undefined,
	houseTypeHall:undefined,
	houseTypeRoom:undefined,
	houseTypeBathroom:undefined,
	estateImgUrls:[],
	remarks:undefined,
}

const handleConfirm = (rule,value,callback) => {
	if(!value){
		callback();
	  }else{
		// 正则判断失败抛出错误，否则直接callback()
		if(/[\u4E00-\u9FA5]/g.test(value)) {
		  callback(new Error("不能输入中文！"));
		}else{
		  callback();
		}
	}
}

// 校验
export const rules = {
	manageBuildingId:[
		{ required: true, message: "请选择楼栋", trigger: "change" },
	],
	manageUnitId:[
		{ required: true, message: "请选择单元", trigger: "change" },
	],
	floorLocation: [
		{ required: true, message: "请输入楼层位置", trigger: "blur" },
	],
	name: [
		{ required: true, message: "请输入房屋名称", trigger: "blur" },
		{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}
	],
	manageEstateTypeId: [
		{ required: true, message: "请选择房屋类型", trigger: "change" },
	],
	indoorArea: [
		{ required: true, message: "请输入室内面积", trigger: "blur" },
	],
	constructionArea: [
		{ required: true, message: "请输入建筑面积", trigger: "blur" },
	],
	isEnableLease: [
		{ required: true, message: "请选择是否开启租赁", trigger: "change" },
	],
}
// 请求头
import store from "@/store";
export const uploadHeaders = {
	"manage-login-token": store.getters.getToken,
}